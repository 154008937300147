import { useCallback, useEffect, useState } from "react";
import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
import CropSquareSharpIcon from "@mui/icons-material/CropSquareSharp";
import DrawerMenu from "../DrawerMenu";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import OptionsMenu from "../OptionsMenu/OptionsMenu";
import { Button, Drawer, Grid } from "@mui/material";
import { API_URL } from "../../../../api";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const Filters = ({ mobGrid, setMobGrid, setFilteredData, products }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState([
    {
      title: "Categories",
      options: ["All"],
    },
  ]);
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");

  const toggleDrawer = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [drawerOpen]);

  const refetch = () => {
    const categoryId = category === "women" ? 2 : category === "men" ? 1 : 1;

    axios
      .get(`${API_URL}/products/category/${categoryId}/subcategories`)
      .then((response) => {
        const newOptions = response.data.map((subcategory) => subcategory.name);

        setFilterOptions((prevFilters) => {
          const updatedOptions = [
            ...prevFilters[0].options,
            ...newOptions,
          ].filter((option, index, self) => self.indexOf(option) === index);

          return [
            {
              ...prevFilters[0],
              options: updatedOptions,
            },
          ];
        });
      })
      .catch((error) => {
        console.error("Error fetching subcategories", error);
      });
  };

  useEffect(() => {
    if (category) {
      refetch();
    }
  }, [category]);
  return (
    <>
      {filterOptions.map((option, index) => (
        <OptionsMenu
          key={index}
          filter={option}
          setFilteredData={setFilteredData}
          products={products}
        />
      ))}
      <Grid container spacing={3} sx={{ display: { sx: "flex", md: "none" } }}>
        <Grid item xs={9}>
          <Button
            onClick={toggleDrawer}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: 15,
              color: "#282828",
              padding: 0,
              bottom: 5,
            }}
          >
            <FilterAltOutlinedIcon />
            Filter
          </Button>
        </Grid>
        <Grid item>
          <GridViewSharpIcon
            onClick={() => setMobGrid(6)}
            style={{ color: mobGrid === 6 ? "black" : "grey" }}
          />
          <CropSquareSharpIcon
            onClick={() => setMobGrid(12)}
            style={{ color: mobGrid === 12 ? "black" : "grey" }}
          />
        </Grid>
      </Grid>
      <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer}>
        <DrawerMenu
          filterOptions={filterOptions}
          toggleDrawer={toggleDrawer}
          setFilteredData={setFilteredData}
          products={products}
        />
      </Drawer>
    </>
  );
};

export default Filters;
