import { Avatar, Box, Button, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSearchParams } from "react-router-dom";

const OptionsMenu = ({
  filter,
  setFilterOption,
  vanishOnMob = true,
  setFilteredData,
  products,
  isOrder,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams] = useSearchParams();
  const subcategory = searchParams.get("subcategory");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, value) => {
    setFilterOption && setFilterOption(value);
    setAnchorEl(null);
  };

  const handleFilterData = (searchItem) => {
    const url = new URL(window.location);
    if (isOrder) setFilterOption();
    else {
      if (searchItem === "All") {
        setFilteredData(products);
        url.searchParams.delete("subcategory");
      } else {
        setFilteredData(
          products?.filter(
            (product) =>
              product.subcategory.name.toLowerCase() ===
              searchItem.toLowerCase()
          )
        );
        url.searchParams.set("subcategory", searchItem);
      }
    }
    window.history.replaceState(null, "", url.toString());
  };
  useEffect(() => {
    if (subcategory) {
      setFilteredData(
        products?.filter(
          (product) =>
            product.subcategory.name.toLowerCase() === subcategory.toLowerCase()
        )
      );
    }
  }, [subcategory, products, setFilteredData]);
  return (
    <>
      <Box sx={{ display: vanishOnMob && { xs: "none", md: "inline-block" } }}>
        <Button
          onClick={handleClick}
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: 20,
            color: "#282828",
          }}
        >
          {filter?.title}
          {anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {filter?.options.map((subOption) => {
            return (
              <MenuItem
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: 15,
                  color: "#282828",
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 6,
                }}
                onClick={(e) => {
                  handleFilterData(subOption);
                  handleClose(e, subOption);
                }}
              >
                {filter.title === "Color" && (
                  <Avatar
                    sx={{
                      bgcolor: subOption,
                      borderRadius: "50%",
                      color: subOption,
                      width: 20,
                      height: 20,
                    }}
                  />
                )}
                {subOption}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </>
  );
};
export default OptionsMenu;
