import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

const Payment = ({ setActiveStep }) => {
  const { values, setFieldValue } = useFormikContext();
  const [paymentMethod, setPaymentMethod] = useState("cash");

  const handlePaymentMethodChange = (event) => {
    const method = event.target.value;
    setPaymentMethod(method);
    setFieldValue("paymentMethod", method);
  };
  useEffect(() => {
    setFieldValue("paymentMethod", paymentMethod);
  }, []);
  return (
    <>
      <Box
        sx={{ marginLeft: { xs: 3, md: 7 } }}
        style={{
          background: "#FFFFFF",
          border: "1px solid #8A8A8A",
          borderRadius: 4,
          padding: 15,
        }}
      >
        <Grid container style={{ marginBottom: 10 }}>
          <Grid item xs={4}>
            <Typography style={{ color: "rgba(0, 0, 0, 0.7)" }}>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>{values?.user?.email}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              style={{
                color: " #282828",
                padding: 0,
                textDecoration: "underline",
              }}
              onClick={() => setActiveStep(1)}
            >
              Change
            </Button>
          </Grid>
        </Grid>
        <Divider style={{ margin: 20 }} />
        <Grid container>
          <Grid item xs={4}>
            <Typography style={{ color: "rgba(0, 0, 0, 0.7)" }}>
              Ship to
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              {" "}
              {values?.user?.address?.address_line1 +
                ", " +
                values?.user?.address?.city +
                ", " +
                values?.user?.address?.country}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              style={{
                color: " #282828",
                padding: 0,
                textDecoration: "underline",
              }}
              onClick={() => setActiveStep(1)}
            >
              Change
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Typography
        style={{
          fontFamily: "Open sans,sans-serif",
          fontWeight: 700,
          fontSize: 17,
          color: " #000000",
          marginTop: 30,
        }}
        sx={{ marginLeft: { xs: 3, md: 7 } }}
      >
        Payment
      </Typography>
      <Typography
        style={{
          fontFamily: "Open sans,sans-serif",
          fontWeight: 500,
          fontSize: 15,
          color: "rgba(0, 0, 0, 0.6)",
          marginBottom: 30,
        }}
        sx={{ marginLeft: { xs: 3, md: 7 } }}
      >
        All transactions are secure and encrypted
      </Typography>

      <Box
        sx={{ marginLeft: { xs: 3, md: 7 } }}
        style={{
          background: "#FFFFFF",
          border: "1px solid #8A8A8A",
          borderRadius: 4,
          paddingLeft: 30,
          paddingBottom: 15,
        }}
      >
        <FormControl fullwidth>
          <RadioGroup
            value={paymentMethod}
            onChange={handlePaymentMethodChange}
          >
            <FormControlLabel
              value="cash"
              control={<Radio disableRipple color="default" />}
              label="Cash on Delivery (COD)"
            />
            <FormControlLabel
              value="visa"
              control={<Radio disableRipple color="default" />}
              label="Pay via (Debit/Credit cards/Wallets) - COMING SOON"
              disabled
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
};
export default Payment;
