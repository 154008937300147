import { Box } from "@mui/material";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ImageContainer = ({ image, index }) => {
  return (
    <Zoom>
      <Box>
        <img
          src={image}
          alt={index}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </Box>
    </Zoom>
  );
};
export default ImageContainer;
