import { Box, Button, CircularProgress, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import Mailto from "../../../components/Mailto/Mailto";
import { API_URL } from "../../../api";
import axios from "axios";
import { useNavigate } from "react-router";

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  width: { xs: "100%", md: "60%" },
};
const Users = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);

  const allUserEmails = () => {
    return rows?.map((row) => {
      return row.email;
    });
  };
  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = rows.filter(
      (item) =>
        item?.first_name?.toLowerCase().includes(searchTerm) ||
        item?.last_name?.toLowerCase().includes(searchTerm) ||
        item?.email?.toLowerCase().includes(searchTerm) ||
        item?.addresses[0]?.phone_number?.includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/users/`)
      .then((response) => {
        setLoading(false);
        setRows(response.data);
        setFilteredData(response.data);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentAdmin");

    !isLoggedIn ? navigate("/admin") : refetch();
  }, []);
  return loading ? (
    <Box
      style={{
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress
        style={{
          color: "black",
        }}
      />
    </Box>
  ) : (
    <TableContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          columnGap: 2,
        }}
      >
        <TextField
          placeholder="Search by name , email or phone number"
          value={searchTerm}
          onChange={handleSearch}
          sx={customTextFieldStyling}
        />
        <Button
          variant="outlined"
          style={{ color: "black", borderColor: "black" }}
        >
          <Mailto emails={allUserEmails()?.join()}>Email all</Mailto>
        </Button>
      </Box>

      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold", fontFamily: "Montserrat" }}>
              Name
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Email
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Profession
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Phone number
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Address
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
              >
                {row.first_name + " " + row.last_name}
              </TableCell>

              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="left"
              >
                {row.email}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="left"
              >
                {row.profession}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="left"
              >
                {row.addresses[0]?.phone_number
                  ? row.addresses[0]?.phone_number
                  : "Not saved"}
              </TableCell>
              <TableCell align="left">
                {row?.addresses.length > 0
                  ? row.addresses[0]?.address_line1 +
                    "," +
                    row.addresses[0]?.city +
                    "," +
                    row.addresses[0]?.postal_code
                  : "Not saved"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default Users;
