import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import embroidery from "../../../../assets/images/embroidery.jpg";
import SizeChart from "../../../../components/SizeChart";

const colors = ["White", "Black", "Red", "Green", "Pink", "Maroon"];
const placement = ["LEFT", "RIGHT"];

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  marginBottom: 2,
};
const Embroidery = ({
  setEmbrioderyData,
  setOpenEmbroidery,
  customization_fees,
}) => {
  const [selectedPlacemnet, setSelectedPlacement] = useState("LEFT");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [selectedColor, setSelectedColor] = useState("White");
  const [err, setError] = useState("");
  const [openSizeChart, setOpenSizeChart] = useState(false);

  const onReset = useCallback(() => {
    setSelectedPlacement("LEFT");
    setName("");
    setTitle("");
    setSelectedColor("Black");
  }, [setSelectedPlacement, setName, setSelectedColor, setTitle]);

  const onAdd = useCallback(() => {
    if (!name) setError("Please Add text");
    else {
      setEmbrioderyData({
        text: name,
        title: title,
        color: selectedColor,
        placement: selectedPlacemnet.toLowerCase(),
      });
      setOpenEmbroidery(false);
    }
  }, [name, title, selectedColor, selectedPlacemnet]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          paddingTop: 5,
          paddingBottom: 3,
        }}
      >
        <Typography
          sx={{ fontSize: { xs: 15, md: 20 } }}
          style={{ fontFamily: "Montserrat", fontWeight: 500 }}
        >
          CUSTOMIZE YOUR SCRUB
        </Typography>
        <Typography
          sx={{ fontSize: { xs: 10, md: 15 } }}
          style={{ fontFamily: "Montserrat", fontWeight: 500 }}
        >
          Customized scrubs{" "}
          <span style={{ fontWeight: 700 }}>cannot be exchanged/refunded</span>{" "}
          .<br /> Please check the{" "}
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => setOpenSizeChart(true)}
          >
            size chart
          </span>{" "}
          before ordering.
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ pl: { md: 5 } }}
        style={{
          marginBottom: 15,
        }}
      >
        <Grid item xs={12} md={6}>
          <Box
            style={{
              display: "flex",
              backgroundColor: "#ECECEC",
            }}
          >
            <img
              alt=""
              src={embroidery}
              style={{
                maxWidth: "100%",
                height: "auto",
                top: 0,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ ml: { xs: 2, md: 0 }, pr: 2 }}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: 15,
              color: "#282828",
              marginBottom: 10,
            }}
          >
            NAME PLACEMENT
          </Typography>
          {placement.map((placement) => {
            return (
              <Button
                variant="outlined"
                onClick={(e) => setSelectedPlacement(e.target.textContent)}
                style={{
                  marginLeft: 3,
                  borderColor: "black",
                  color: selectedPlacemnet === placement ? "white" : "black",
                  backgroundColor:
                    selectedPlacemnet === placement ? "black" : "white",
                }}
              >
                {placement}
              </Button>
            );
          })}

          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: 15,
              color: "#282828",
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            TEXT
          </Typography>
          <TextField
            label="Text"
            sx={customTextFieldStyling}
            fullWidth
            onChange={(e) => setName(e.target.value)}
            value={name}
            multiline
          >
            {name}
          </TextField>

          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: 15,
              color: "#282828",
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            COLOR:{" "}
            <span style={{ color: "rgba(40, 40, 40, 0.8)" }}>
              {selectedColor}
            </span>
          </Typography>
          <Grid container spacing={1}>
            {colors.map((color) => {
              return (
                <Grid item>
                  <Avatar
                    sx={{ width: 20, height: 20 }}
                    style={{
                      color: color,
                      backgroundColor: color,
                      border: color === "White" && "solid 1px black",
                    }}
                    onClick={() => setSelectedColor(color)}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: 15,
              color: "#282828",
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            Customization fees: {customization_fees} LE
          </Typography>
          <Divider style={{ margin: "30px 40px 20px 0px" }} />
          <Button
            onClick={onReset}
            variant="outlined"
            style={{ color: "black", borderColor: "black" }}
          >
            Reset
          </Button>
          <Button
            onClick={onAdd}
            style={{ color: "white", backgroundColor: "black", marginLeft: 4 }}
          >
            Add
          </Button>
          {err && (
            <Typography
              style={{
                color: "red",
                fontSize: 16,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              {err}!
            </Typography>
          )}
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setOpenSizeChart(false)}
        open={openSizeChart}
        fullWidth={true}
        maxWidth="md"
      >
        <SizeChart />
      </Dialog>
    </>
  );
};
export default Embroidery;
