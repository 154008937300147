import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Embroidery from "./Components/Embroidery/Embroidery";
import ReadMoreSection from "./Components/ReadMoreSection";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Cart from "../Cart/Cart";
import Carousel from "../../components/Carousel/Carousel";
import ImageContainer from "../../components/ImageContainer/ImageContainer";
import { useParams } from "react-router-dom";
import { API_URL, IMAGE_URL } from "../../api";
import axios from "axios";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import SizeChart from "../../components/SizeChart";
import CircularLoaderWithLogo from "../../components/Loader/Loader";
import VerticalCarousel from "../../components/VerticalCarousel";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const sizeOrder = ["xs", "s", "m", "l", "xl", "2xl", "3xl", "4xl", "5xl"];
const sortedSizes = (sizes) => {
  return sizes
    ?.filter((size) => sizeOrder.includes(size.size.toLowerCase()))
    .sort(
      (a, b) =>
        sizeOrder.indexOf(a.size.toLowerCase()) -
        sizeOrder.indexOf(b.size.toLowerCase())
    );
};
const colorMap = {
  burgundy: "#800020",
  "royal blue": "#4169e1",
  "olive green": "olive",
  "navy blue": "navy",
};

const getMappedColor = (colorName) => {
  return colorMap[colorName.trim().toLowerCase()] || colorName;
};
const ProductDetails = () => {
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedColor, setSelectedColor] = useState();
  const [selectedSize, setSelectedSize] = useState("");
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
  const [openEmbroidery, setOpenEmbroidery] = useState(false);
  const [embroideryData, setEmbrioderyData] = useState({});
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [productSizes, setProductSizes] = useState([]);
  const [colorImgaes, setColorImages] = useState([]);
  const { id } = useParams();
  const [initialProductQuantity, setInitialProductQuantity] = useState(0);
  const [openSizeChart, setOpenSizeChart] = useState(false);
  const [err, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/products/${id}`)
      .then((response) => {
        setLoading(false);
        setProduct(response.data);
        setSelectedColor(response.data.colors[0].value);
        setProductSizes(sortedSizes(response.data.colors[0].sizes_quantities));
        setColorImages(
          response.data.colors[0].images.filter((img) => img != "")
        );
        setSelectedImage(response.data.colors[0].images[0]);
        // handleSizesDeductionCary(response.data.colors[0].value, response.data);
      })
      .catch();
  }, []);

  const AddToBag = useCallback(() => {
    // handleSizesDeductionCart();

    setError("");
    if (!selectedSize) setError("Please select a size");
    else {
      let cart = localStorage.getItem("cart");
      if (cart) cart = JSON.parse(localStorage.getItem("cart"));
      else {
        localStorage.setItem("cart", []);
      }
      const productImage = product.colors.filter(
        (color) => color.value === selectedColor
      )[0].images[0];
      const hasEmbroideryData = Object.keys(embroideryData).length > 0;
      const price =
        product?.sale_price > 0 ? product?.sale_price : product?.price;
      const selectedProduct = {
        id: product?.id,
        size: selectedSize,
        color: selectedColor,
        // image: product?.colors[0].images[0],

        image: IMAGE_URL + "/" + productImage,
        title: product?.name,
        price: hasEmbroideryData ? price + product?.customization_fees : price,
        customization_fees: product?.customization_fees,
        quantity: 1,
        embriodery: embroideryData,
        product_initail_quantity: initialProductQuantity,
      };
      var updatedCart;
      if (!!cart) {
        updatedCart = [...cart, selectedProduct];
      } else {
        updatedCart = [selectedProduct];
      }
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      setEmbrioderyData({});
      setCartDrawerOpen(true);
    }
  }, [selectedSize, selectedColor, embroideryData]);

  const handleSelectSize = useCallback(
    (e, size) => {
      setSelectedSize(e.currentTarget.textContent);
      setInitialProductQuantity(size.quantity);
    },
    [setSelectedSize]
  );

  // const handleSizesDeductionCart = useCallback(() => {
  //   let cart = localStorage.getItem("cart");
  //   if (cart) {
  //     cart = JSON.parse(localStorage.getItem("cart"));
  //     const cartItems = cart.filter((item) => {
  //       return (
  //         item.id === product.id ||
  //         item.color === selectedColor ||
  //         item.size === selectedSize
  //       );
  //     });
  //     const totalQuantityInCart = cartItems.reduce(
  //       (sum, item) => sum + item.quantity,
  //       0
  //     );

  //     console.log(
  //       "testttt item",
  //       cart,
  //       selectedColor,
  //       selectedSize,
  //       itemsToBeAdded,
  //       totalQuantityInCart,
  //       productSizes.map((size) => {
  //         if (size === selectedSize) {
  //           size.quantity = size.quantity - totalQuantityInCart;
  //         }
  //       })
  //     );
  //     // setProductSizes(
  //     //   productSizes.map((size) => {
  //     //     if (size === selectedSize) {
  //     //       size.quantity = size.quantity = totalQuantityInCart;
  //     //     }
  //     //   })
  //     // );
  //   }
  // }, [product, selectedColor, selectedSize]);
  return loading ? (
    <Box
      style={{
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularLoaderWithLogo />
    </Box>
  ) : (
    <>
      <Box sx={{ display: { xs: "initial", md: "none" } }}>
        {colorImgaes.filter((image) => image !== "").length == 1 ? (
          <ImageContainer image={IMAGE_URL + "/" + colorImgaes[0]} />
        ) : (
          <Carousel
            slidesToShow={1}
            slidesToScroll={1}
            sliderContentData={colorImgaes.filter((image) => image !== "")}
            infinite
            sliderContent={colorImgaes
              .filter((image) => image !== "")
              .map((image, index) => (
                <div key={index} className="slick-slide">
                  <ImageContainer image={IMAGE_URL + "/" + image} />
                </div>
              ))}
          />
        )}
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          paddingLeft: {
            xs: 0,
            md: 10,
          },
          paddingTop: {
            xs: 0,
            md: 6,
          },
        }}
      >
        <Grid item xs={12} md={2} sx={{ display: { xs: "none", md: "block" } }}>
          <VerticalCarousel
            images={colorImgaes}
            setSelectedImage={setSelectedImage}
          />
        </Grid>

        <Grid item xs={12} md={5} sx={{ display: { xs: "none", md: "block" } }}>
          <Box
            style={{
              width: "100%",
              height: 500,
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Zoom>
              <img
                alt=""
                src={IMAGE_URL + "/" + selectedImage}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </Zoom>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          style={{ marginTop: 20 }}
          sx={{ marginLeft: { xs: 2, md: 2 } }}
        >
          <Typography
            sx={{ fontSize: { xs: 20, md: 23 } }}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: "#282828",
            }}
          >
            {product?.name}
          </Typography>
          <Box style={{ display: "flex", flexDirection: "row", columnGap: 20 }}>
            <Typography
              sx={{ fontSize: { xs: 18, md: 22 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginTop: 15,
                color: " #282828",
                textDecoration: product?.sale_price > 0 && "line-through",
              }}
            >
              LE {product?.price}
            </Typography>
            {product?.sale_price > 0 && (
              <Typography
                sx={{ fontSize: { xs: 18, md: 22 } }}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  marginTop: 15,
                  color: " red",
                }}
              >
                LE {product?.sale_price}
              </Typography>
            )}
          </Box>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: 5,
              marginTop: 20,
            }}
          >
            <Typography
              sx={{ fontSize: { xs: 18, md: 20 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: " #282828",
              }}
            >
              COLOR :
            </Typography>
            <Typography
              sx={{ fontSize: { xs: 18, md: 20 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: "rgba(40, 40, 40, 0.8)",
              }}
            >
              {selectedColor}
            </Typography>
          </div>
          <Grid container spacing={1}>
            {product?.colors?.map((color) => {
              return (
                <Grid item>
                  <Avatar
                    sx={{ width: 20, height: 20 }}
                    style={{
                      color: getMappedColor(color.value),
                      backgroundColor: getMappedColor(color.value),
                    }}
                    onClick={() => {
                      setSelectedColor(color.value);
                      setProductSizes(sortedSizes(color.sizes_quantities));
                      setColorImages(color.images);
                      setSelectedImage(color.images[0]);
                      setSelectedSize("");
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={9}>
              <Typography
                sx={{ fontSize: { xs: 18, md: 20 } }}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: "#282828",
                }}
              >
                Size
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: { xs: 15, md: 18 } }}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setOpenSizeChart(true)}
              >
                Size chart
              </Typography>
            </Grid>
          </Grid>

          {productSizes?.map((size) => (
            <Button
              sx={{ fontSize: { xs: 15, md: 20 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: "#282828",
                borderColor: "#282828",
                margin: 5,
                backgroundColor: selectedSize === size.size ? "black" : "white",
                color: selectedSize === size.size ? "white" : "black",
              }}
              disabled={size.quantity <= 0}
              variant="outlined"
              onClick={(e) => {
                handleSelectSize(e, size);
              }}
            >
              {size.size}
              {size.quantity <= 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "80%",
                    height: "1px",
                    backgroundColor: "#000",
                    transform: "translate(-50%, -50%) rotate(45deg)",
                    zIndex: 1,
                  }}
                ></div>
              )}
            </Button>
          ))}
          <Divider style={{ marginTop: 20 }} />
          <Button
            onClick={() => setOpenEmbroidery(true)}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: 20,
              color: "#282828",
              marginTop: 10,
            }}
          >
            CUSTOMIZE YOUR SCRUB
          </Button>
          {Object.keys(embroideryData).length > 0 && (
            <Box
              style={{
                marginLeft: 10,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: 15,
                  color: "#282828",
                  marginTop: 5,
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 2,
                  alignItems: "center",
                }}
              >
                Embroidery added successsfuly <CheckCircleOutlineOutlinedIcon />
              </Typography>
              <Typography
                sx={{ fontSize: { xs: 10, md: 15 } }}
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
              >
                Customized scrubs{" "}
                <span style={{ fontWeight: 700 }}>
                  cannot be exchanged/refunded
                </span>{" "}
                .<br /> Please check the{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => setOpenSizeChart(true)}
                >
                  size chart
                </span>{" "}
                before ordering.
              </Typography>
            </Box>
          )}
          <Button
            sx={{ fontSize: { xs: 17, md: 20 } }}
            style={{
              width: "95%",
              background: "#282828",
              color: " #FFFFFF",
              FontFamily: "Montserrat",
              fontWeight: 500,
              marginTop: 30,
            }}
            onClick={AddToBag}
          >
            ADD TO BAG
          </Button>
          {err && (
            <Typography
              style={{
                color: "red",
                fontSize: 16,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              {err}!
            </Typography>
          )}
          <Box style={{ marginTop: 40 }}>
            <ReadMoreSection
              section={{
                title: "DETAILS",
                description: product.description,
              }}
            />
            <ReadMoreSection
              section={{
                title: "FIT",
                description: product.fit,
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Dialog onClose={() => setOpenEmbroidery(false)} open={openEmbroidery}>
        <Embroidery
          setOpenEmbroidery={setOpenEmbroidery}
          setEmbrioderyData={setEmbrioderyData}
          customization_fees={product.customization_fees}
        />
      </Dialog>
      <Dialog
        onClose={() => setOpenSizeChart(false)}
        open={openSizeChart}
        fullWidth={true}
        maxWidth="md"
      >
        <SizeChart />
      </Dialog>
      <Drawer
        anchor="top"
        open={cartDrawerOpen}
        onClose={() => setCartDrawerOpen(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            mr: { xs: 5, md: 10 },
            mt: 5,
          }}
        >
          <CloseOutlinedIcon
            onClick={() => setCartDrawerOpen(false)}
            sx={{ fontSize: 30 }}
          />
        </Box>

        <Cart setCartDrawerOpen={setCartDrawerOpen} />
      </Drawer>
    </>
  );
};
export default ProductDetails;
