import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
} from "@mui/material";
import OrderSummary from "../../../components/OrderSummary/OrderSummary";
import UpdateStatus from "./Components/UpdateStatus/UpdateStatus";
import OptionsMenu from "../../Products/components/OptionsMenu";
import CloseIcon from "@mui/icons-material/Close";
import { API_URL } from "../../../api";
import axios from "axios";
import { useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDelete from "../../../components/ConfirmDelete";

const filterOptions = {
  title: "Status",
  options: ["pending", "delivered", "refund"],
};

const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  width: { xs: "100%", md: "50%" },
};
const Orders = () => {
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [openUpdateStatus, setopenUpdateStatus] = useState(false);
  const [filterOption, setFilterOption] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentOrderDetails, setCurrentOrderDetails] = useState({});
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const refetch = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/orders/`)
      .then((response) => {
        setLoading(false);
        setRows(response.data);
        setFilteredData(response.data);
      })
      .catch();
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("currentAdmin");
    !isLoggedIn ? navigate("/admin") : refetch();
  }, []);

  const handleSetOption = (filterOption) => {
    setFilterOption(filterOption, () => {
      return filterOption;
    });
    setFilteredData(rows.filter((row) => row.status === filterOption));
  };
  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setFilterOption("");
    const filtered = rows.filter(
      (item) =>
        item?.order_number?.includes(searchTerm) ||
        item?.user_id.email?.includes(searchTerm)
    );
    setFilteredData(filtered);
  };
  const handleDeleteOrder = () =>
    axios
      .delete(`${API_URL}/orders/${currentOrderDetails.id}`)
      .then(() => {
        setOpenConfirmDelete(false);
        refetch();
      })
      .catch((err) => {});

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress style={{ color: "black" }} />
    </div>
  ) : (
    <TableContainer>
      <TextField
        placeholder="Search by order number or email"
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginTop: 10, marginBottom: 20 }}
        sx={customTextFieldStyling}
      />
      <OptionsMenu
        filter={filterOptions}
        setFilterOption={handleSetOption}
        vanishOnMob={false}
        products={rows}
        isOrder
      />
      <Box
        border="1px solid black"
        display={filterOption ? "inline-block" : "none"}
        borderRadius="4px"
      >
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            padding: 10,
          }}
        >
          {filterOption}
          <CloseIcon
            onClick={() => {
              setFilteredData(rows);
              setFilterOption("");
            }}
          />
        </Typography>
      </Box>

      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold", fontFamily: "Montserrat" }}>
              Order number{" "}
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Email
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              Status
            </TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row) => (
            <TableRow
              key={row?.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ fontWeight: "bold", fontFamily: "Montserrat" }}
              >
                #{row?.order_number}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="left"
              >
                {row?.user_id?.email}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Montserrat", fontWeight: 500 }}
                align="left"
              >
                {row?.status}
              </TableCell>
              <TableCell align="left">
                <Button
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    fontFamily: "Montserrat",
                  }}
                  onClick={() => {
                    setCurrentOrderDetails(row);
                    setOpenOrderDetails(true);
                  }}
                >
                  View details
                </Button>
              </TableCell>
              <TableCell align="left">
                <Button
                  variant="outlined"
                  style={{
                    color: "black",
                    fontFamily: "Montserrat",
                    borderColor: "black",
                  }}
                  onClick={() => {
                    setCurrentOrderDetails(row);
                    setopenUpdateStatus(true);
                  }}
                >
                  Update status
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    setOpenConfirmDelete(true);
                    setCurrentOrderDetails(row);
                  }}
                >
                  <DeleteIcon style={{ color: "red" }} />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        onClose={() => setOpenOrderDetails(false)}
        open={openOrderDetails}
        fullWidth
      >
        <OrderSummary orderDetails={currentOrderDetails} />
      </Dialog>
      <Dialog
        onClose={() => setopenUpdateStatus(false)}
        open={openUpdateStatus}
        fullWidth
      >
        <UpdateStatus
          orderDetails={currentOrderDetails}
          setopenUpdateStatus={setopenUpdateStatus}
          refetch={refetch}
        />
      </Dialog>
      <Dialog
        onClose={() => setOpenConfirmDelete(false)}
        open={openConfirmDelete}
      >
        <ConfirmDelete
          onClose={() => setOpenConfirmDelete(false)}
          text={"order #" + currentOrderDetails.order_number}
          onDelete={(e) => handleDeleteOrder(e)}
        />
      </Dialog>
    </TableContainer>
  );
};
export default Orders;
