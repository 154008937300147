import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const DrawerMenu = ({
  filterOptions,
  toggleDrawer,
  setFilteredData,
  products,
}) => {
  const [searchParams] = useSearchParams();
  const subcategory = searchParams.get("subcategory");

  const handleFilterData = (searchItem) => {
    const url = new URL(window.location);
    if (searchItem === "All") {
      setFilteredData(products);
      url.searchParams.delete("subcategory");
    } else {
      setFilteredData(
        products?.filter(
          (product) =>
            product.subcategory.name.toLowerCase() === searchItem.toLowerCase()
        )
      );
      url.searchParams.set("subcategory", searchItem);
    }
    window.history.replaceState(null, "", url.toString());
  };
  useEffect(() => {
    if (subcategory) {
      setFilteredData(
        products?.filter(
          (product) =>
            product.subcategory.name.toLowerCase() === subcategory.toLowerCase()
        )
      );
    }
  }, [subcategory, products, setFilteredData]);
  return (
    <div style={{ width: "100vw" }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={10}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: 20,
              color: "#282828",
              margin: 10,
            }}
          >
            Filter by
          </Typography>
        </Grid>
        <Grid item>
          <CloseOutlinedIcon
            onClick={toggleDrawer}
            style={{ fontSize: 30, marginRight: 0 }}
          />
        </Grid>
      </Grid>

      <Divider />
      {filterOptions.map((filter) => {
        return (
          <Box>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 20,
                color: "#282828",
                margin: 10,
              }}
            >
              {filter.title}
            </Typography>
            <List style={{ margin: 10 }}>
              {filter.options.map((subOption) => {
                return (
                  <ListItem
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: 15,
                      color: "#282828",
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 6,
                    }}
                    onClick={(e) => {
                      handleFilterData(subOption);
                      toggleDrawer();
                    }}
                  >
                    {filter.title === "Color" && (
                      <Avatar
                        sx={{
                          bgcolor: subOption,
                          borderRadius: "50%",
                          color: subOption,
                          width: 20,
                          height: 20,
                        }}
                      />
                    )}
                    {subOption}
                  </ListItem>
                );
              })}
            </List>
            <Divider />
          </Box>
        );
      })}
      <Divider />
    </div>
  );
};
export default DrawerMenu;
