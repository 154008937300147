import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import ReadMoreSection from "../ProductDetails/Components/ReadMoreSection/ReadMoreSection";

const questionsSection = [
  {
    title: "SHIPPING & DELIVERY",
    subOptions: [
      {
        title: "How long will it take for me to receive my order?",
        description:
          "The Standard courier delivery time frame is 3-8 working days from the time of placing your order depending on your location. This is applicable only to local deliveries. ",
      },
      {
        title:
          "Can I change my shipping address after my order has been confirmed?",
        description:
          "Unfortunately, we are unable to redirect orders once your order is confirmed. Therefore, please ensure you provide the correct shipping address.",
      },
      {
        title: "There is a missing item in my order, what should I do?",
        description:
          "We apologize for sending you an incomplete order. Please contact us at info@skrbs.com and we will get back to you as soon as we can.",
      },
      {
        title: "I’ve received a defective item, what should I do?",
        description:
          "We apologize if you had received a defective item from us. Please contact us at info@skrbs.com with a snapshot of the product and we will get back to you as soon as we can.",
      },
      {
        title: "I’ve received an incorrect item, what should I do?",
        description:
          "We apologize for sending you the wrong item. Please contact us at info@skrbs.com and we will get back to you as soon as we can.",
      },
    ],
  },
  {
    title: "ORDERING",
    subOptions: [
      {
        title: "Do I need to open an account in order to shop with you?",
        description:
          "No, you don’t need to. You can make purchases and check out as a guest every time.However, by setting up an account with us, it will allow you to order without having to enter your details every time you shop with us. You can sign up right now, or you can first start shopping and create your account before you check out at the shopping cart page.",
      },
      {
        title: "How do I order?",
        description:
          "Shop for the items you want and add it to your shopping cart. When you finish, you can proceed to your shopping cart and check out. Check and ensure that all information is correct before confirming your purchases.",
      },
      {
        title: "How do I pay for my orders?",
        description:
          "You can pay for your orders either cash on delivery or through our secure online method",
      },
      {
        title: "How will I know if my order is confirmed?",
        description:
          "After you have placed your order, you will receive an acknowledgement e-mail from us to confirm that your orders have been received.",
      },
    ],
  },
  {
    title: "EXPERT ADVICE",
    subOptions: [
      {
        title: "How do I find the right size and fit",
        description:
          "You can check our size guard and if you still need any further support please contact us at info@skrbs.com and we are more than happy to help you with it. ",
      },
    ],
  },
];

const FAQ = () => {
  useEffect(() => {
    document.title = "SKRBS - FAQ";
    return () => {
      document.title = "SKRBS";
    };
  }, []);
  return (
    <Box
      sx={{
        paddingLeft: {
          xs: 5,
          md: 10,
        },
      }}
      style={{
        paddingTop: 30,
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "30px",
            color: "#282828",
          }}
        >
          FAQ
        </Typography>
      </Box>

      <ReadMoreSection
        section={{
          title: "RETURNS & EXCHANGE",
          description:
            "Please note that we do not accept returns on custom-made products, embroidered items and final sale merchandise.Returns must be in new, unworn condition with tags attached, in the state you received them. We reserve the right to refuse worn or damaged merchandise.",
        }}
      />
      {questionsSection.map((section) => {
        return (
          <ReadMoreSection
            section={{
              title: section.title,
              description: section.subOptions.map((subSection) => (
                <ReadMoreSection
                  section={{
                    title: subSection.title,
                    description: subSection.description,
                  }}
                  sub={true}
                />
              )),
            }}
          />
        );
      })}
    </Box>
  );
};
export default FAQ;
