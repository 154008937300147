import { Grid, Typography } from "@mui/material";
import GridRecord from "../GridRecord/GridRecord";

const ProductRecord = ({ product }) => {
  return (
    <>
      <GridRecord
        primaryText={product.product.name}
        secondaryText={
          "LE " + product?.product.sale_price > 0
            ? product?.product?.sale_price
            : product.product.price
        }
        primaryFontWeight={700}
        secondaryFontWeight={700}
        marginBottom={0}
      />
      <Grid container spacing={1} style={{ marginBottom: 5, marginTop: 5 }}>
        <Grid item xs={3} md={2}>
          <Typography
            sx={{ fontSize: { xs: 12, md: 15 } }}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: "#282828",
            }}
          >
            QTY:{product.quantity}
          </Typography>
        </Grid>
        <Grid item xs={5} md={4}>
          <Typography
            sx={{ fontSize: { xs: 12, md: 15 } }}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: "#282828",
            }}
          >
            Color:{product.color}
          </Typography>
        </Grid>{" "}
        <Grid item xs={2} md={3}>
          <Typography
            sx={{ fontSize: { xs: 12, md: 15 } }}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: "#282828",
            }}
          >
            Size:{product.size}
          </Typography>
        </Grid>
      </Grid>
      {product?.customization && (
        <Grid container style={{ marginBottom: 20 }} spacing={1}>
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: { xs: 12, md: 15 } }}
              style={{
                fontFamily: "Montserrat",
                color: "#282828",
                fontWeight: 600,
              }}
            >
              CUSTOMIZATION:
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              sx={{ fontSize: { xs: 12, md: 15 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                color: "#282828",
                whiteSpace: "pre-line",
              }}
            >
              Text: {product?.customization?.text}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography
              sx={{ fontSize: { xs: 12, md: 15 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                color: "#282828",
              }}
            >
              Color:{product?.customization?.color}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              sx={{ fontSize: { xs: 12, md: 15 } }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                color: "#282828",
              }}
            >
              Placement: {product?.customization?.placement}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default ProductRecord;
