import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { FieldArray, Form, Formik, Field } from "formik";
import FormField from "../../../../../components/FormField";
import axios from "axios";
import { API_URL, IMAGE_URL } from "../../../../../api";
import { ButtonBase, CircularProgress, Divider } from "@mui/material";
import { Box, Button } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
const customTextFieldStyling = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
};

const AddorEditProduct = ({
  isEdit = false,
  product,
  setopenEditProduct,
  setOpenAddProduct,
  refetch,
  initialData,
}) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMessage] = useState(false);
  const [error, setError] = useState("");
  const [categorieslist, setCategoriesList] = useState([]);

  const initialValues = useMemo(
    () => ({
      name: "",
      description: "",
      fabric_care: "",
      fit: "",
      price: "",
      category: "",
      subcategory: "",
      colors: [
        {
          value: "",
          sizes_quantities: [{ size: "", quantity: "" }],
          images: [""],
        },
      ],
    }),
    [product, isEdit]
  );
  const handleAddorEditProduct = async (event, values) => {
    setError("");
    event.preventDefault();
    setLoading(true);
    if (isEdit) {
      axios
        .patch(`${API_URL}/products/${product.id}`, values, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setLoading(false);
          setMessage("product added successfully");
          setopenEditProduct(false);
          refetch();
        })
        .catch((err) => {
          setLoading(false);
          err.message == "Network Error"
            ? setError(
                "Failed to Save ! Your images might be too large, compress them and try again."
              )
            : setError("Failed to Save !");
        });
    } else {
      axios
        .post(`${API_URL}/products/`, values, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setLoading(false);
          setMessage("product added successfully");
          setOpenAddProduct(false);
          refetch();
        })
        .catch((err) => {
          setLoading(false);
          err.message == "Network Error"
            ? setError(
                "Failed to Save ! Your images might be too large, compress them and try again."
              )
            : setError("Failed to Save !");
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/products/category/subcategory`)
      .then((response) => {
        setLoading(false);
        setCategoriesList(
          response.data.map((item) => ({
            label: item?.name,
            value: item?.id,
            subCategories: item?.subCategories,
          }))
        );
      })
      .catch();
  }, [product]);

  return (
    <Box
      style={{
        background: "#FFFFFF",
        padding: 50,
        width: "100%",
      }}
    >
      <Formik
        initialValues={isEdit ? initialData : initialValues}
        onSubmit={(values) => console.log(values)}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                fontSize: 25,
              }}
            >
              Product Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`name`}
                  label="Name"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="select"
                  name={`category`}
                  label="Category"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  options={categorieslist}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="select"
                  name={`subcategory`}
                  label="Sub-Category"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                  options={
                    categorieslist
                      ?.find((item) => item.value === values.category)
                      ?.subCategories?.map((item) => ({
                        label: item.name,
                        value: item.id,
                      })) || []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="input"
                  name={`price`}
                  label="Price/EGP"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="input"
                  name={`sale_price`}
                  label="Price/EGP after sale (optional)"
                  variant="standard"
                  fullwidth
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`tag`}
                  label="Custom tag"
                  variant="standard"
                  fullwidth
                  multiline
                  customizedStyling={customTextFieldStyling}
                  helperText="To be applied to product in products list. Example: New"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`customization_fees`}
                  label="Customization Fees"
                  variant="standard"
                  fullwidth
                  multiline
                  customizedStyling={customTextFieldStyling}
                  helperText="Extra fees added for embroidery. Default = 100LE"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`description`}
                  label="Description"
                  variant="standard"
                  fullwidth
                  multiline
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  type="input"
                  name={`fit`}
                  label="Fit"
                  variant="standard"
                  fullwidth
                  multiline
                  customizedStyling={customTextFieldStyling}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FieldArray name="colors">
                  {({ push, remove }) => (
                    <div>
                      {values.colors?.map((color, index) => (
                        <div key={index}>
                          <Grid container spacing={2}>
                            <Grid item xs={8}>
                              <FormField
                                type="input"
                                name={`colors.${index}.value`}
                                label="Color"
                                variant="standard"
                                fullwidth
                                customizedStyling={customTextFieldStyling}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                style={{
                                  color: "black",
                                }}
                                onClick={() => remove(index)}
                              >
                                <RemoveCircleOutlineIcon />
                              </Button>
                            </Grid>
                          </Grid>

                          <FieldArray name={`colors.${index}.sizes_quantities`}>
                            {({ push: pushSQ, remove: removeSQ }) => (
                              <div>
                                {color.sizes_quantities.map(
                                  (size_quantity, sqIndex) => (
                                    <div key={sqIndex}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                          <FormField
                                            type="input"
                                            name={`colors.${index}.sizes_quantities.${sqIndex}.size`}
                                            label="Size"
                                            variant="standard"
                                            fullwidth
                                            customizedStyling={
                                              customTextFieldStyling
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={4}>
                                          <FormField
                                            type="input"
                                            name={`colors.${index}.sizes_quantities.${sqIndex}.quantity`}
                                            label="Quantity"
                                            variant="standard"
                                            fullwidth
                                            customizedStyling={
                                              customTextFieldStyling
                                            }
                                          />
                                        </Grid>

                                        <Grid
                                          item
                                          xs={2}
                                          style={{
                                            display: "flex",
                                            justifyContet: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            style={{
                                              color: "black",
                                            }}
                                            onClick={() =>
                                              pushSQ({ size: "", quantity: "" })
                                            }
                                          >
                                            <AddCircleOutlineIcon />
                                          </Button>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          style={{
                                            display: "flex",
                                            justifyContet: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {color.sizes_quantities.length >
                                            1 && (
                                            <Button
                                              style={{
                                                color: "black",
                                              }}
                                              onClick={() => removeSQ(sqIndex)}
                                            >
                                              <RemoveCircleOutlineIcon />
                                            </Button>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </FieldArray>
                          <FieldArray name={`colors.${index}.images`}>
                            {({ push: pushImg, remove: removeImg }) => (
                              <div style={{ marginTop: 20 }}>
                                {color.images.map((image, imgIndex) => (
                                  <div key={imgIndex}>
                                    {/* Display image if exists */}
                                    {image && (
                                      <>
                                        <img
                                          src={
                                            !isEdit
                                              ? URL.createObjectURL(image)
                                              : IMAGE_URL + "/" + image
                                          }
                                          alt={`Image ${imgIndex}`}
                                          style={{
                                            maxWidth: "100px",
                                            maxHeight: "100px",
                                          }}
                                        />
                                        <Button
                                          style={{ color: "black" }}
                                          type="button"
                                          onClick={() => removeImg(imgIndex)}
                                        >
                                          <RemoveCircleOutlineIcon />{" "}
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                ))}
                                <input
                                  type="file"
                                  onChange={(event) => {
                                    const files = event.currentTarget.files;
                                    const newImages = Array.from(files);
                                    const updatedImages = [
                                      ...color.images,
                                      ...newImages,
                                    ]; // Concatenate new images with existing ones
                                    const updatedColors = [...values.colors];
                                    updatedColors[index].images = updatedImages;
                                    handleChange({
                                      target: {
                                        name: `colors.${index}.images`,
                                        value: updatedImages,
                                      },
                                    });
                                  }}
                                  multiple
                                />

                                <Button
                                  onClick={() => pushImg("")}
                                  style={{ color: "black" }}
                                >
                                  <AddCircleOutlineIcon />
                                </Button>
                              </div>
                            )}
                          </FieldArray>
                          <Divider style={{ marginTop: 10 }} />
                        </div>
                      ))}
                      <Button
                        onClick={() =>
                          push({
                            value: "",
                            sizes_quantities: [{ size: "", quantity: "" }],
                            images: [],
                          })
                        }
                        variant="outlined"
                        style={{
                          color: "black",
                          borderColor: "black",
                          marginTop: 10,
                        }}
                      >
                        Add Color
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
            {error && (
              <Typography
                style={{ marginTop: 3, marginBottom: 3, color: "red" }}
              >
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              style={{ background: "black", color: "white", marginTop: 30 }}
              fullWidth
              onClick={(e) => handleAddorEditProduct(e, values)}
            >
              {loading ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                "Save"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default AddorEditProduct;
